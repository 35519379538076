<template>
    <div class="m-auto flex items-center gap-2">
        <p class="font-poppins text-2xl font-semibold bg-transparent">Loading</p>
        <v-progress-circular size="20" color="primary" indeterminate></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import pinpointClient from '@/clients/pinpointClient'
import { useDealerStore } from '@/stores/dealer/dealer'
import { BANK_ACCOUNT_STATUS } from '@/models/Dealer/BankAccount'
import { initial } from 'lodash'

defineOptions({
    name: 'EmailAuthenticationSession',
})

const route = useRoute()
const router = useRouter()
const authenticatedEmail = route.query.email as string
const token = route.query.token as string
const dealerID = route.query.dealerID as string
const callback = route.query.callback as string
const isInitialSetup = route.query.initial_setup as string
const isPrimaryContact = route.query.is_primary_contact as string

onMounted(async () => {
    const dealerStore = useDealerStore()
    const response = await createSession()
    if (!response || response.status >= 400) {
        router.push({ name: 'EmailAuthentication', params: { dealer_id: dealerID } })
        return
    }

    dealerStore.isPrivileged = response.data.is_privileged
    dealerStore.userName = response.data.email

    if (response.data.is_initial_setup) {
        return router.push({ name: 'Setup', params: { dealer_id: dealerID } })
    }

    if (response.data.is_privileged) {
        await dealerStore.getBankInformation(Number(dealerID))
        if (dealerStore.bankAccount.status !== BANK_ACCOUNT_STATUS.VERIFIED) {
            return router.push({ name: 'Dealer', params: { dealer_id: dealerID } })
        }
    }

    if (callback) {
        router.push(callback)
    } else {
        router.push({ name: 'Accounts', params: { dealer_id: dealerID } })
    }
})

async function createSession() {
    const config = {
        params: {
            email: authenticatedEmail,
            token,
            dealer_id: dealerID,
            initial_setup: isInitialSetup,
            is_primary_contact: isPrimaryContact,
        },
        data: { ignoreGlobalCatch: true },
    }
    try {
        return await pinpointClient.get('/auth/session', config)
    } catch {
        return
    }
}
</script>
