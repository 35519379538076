<template>
    <div class="bg-vuetify-background px-10 py-4">
        <div class="flex flex-col bg-white p-5">
            <div class="flex flex-row items-center justify-center w-full">
                <img src="@/assets/subprime.svg" class="w-36 h-9" alt="policy-documents-header" />
            </div>
            <div class="divide-y divide-slate-300 divide-solid">
                <policy-documents-description
                    v-for="(document, index) in documents"
                    :key="`document-${index}`"
                    class="py-8"
                    :title="document.title"
                    :description="document.description"
                    :link="document.link" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { defineComponent } from 'vue'
import PolicyDocumentsDescription from '@/components/partials/policyDocuments/PolicyDocumentsDescription.vue'
defineComponent({
    name: 'PolicyDocumentsModal',
})

// initializations
type Documents = {
    title: string
    description: string
    link: string
}
const documents: Documents[] = [
    {
        title: 'Simplified Underwriting Guidelines',
        description:
            "PinPoint eliminates the need for traditional term sheets. Still, you will need to know Arivo's few simple underwriting guidelines and lienholder address.",
        link: '/documents/Arivo_Guidelines.pdf',
    },
    {
        title: 'Arivo’s Electronic Lien and Title Numbers',
        description:
            "If your state uses an electronic lien and title (ELT) system, you will need Arivo's ELT identification numbers when submitting title lien information to your state's DMV.",
        link: '/documents/Arivo_ELT_Numbers.pdf',
    },
    {
        title: 'Fast Fund Enrollment Form',
        description: `If your dealership wants to participate in Arivo's Fast Fund and Same Day Funding programs, sign the Fast Fund Addendum to Arivo's Master Dealer Agreement.\n\nBy enrolling in Arivo's Fast Fund program, we can dramatically reduce the funding time, even fund your deals on the same day you submit your documents. The Addendum outlines the conditions and processes required to use Arivo's Fast Fund Program. `,
        link: '/documents/Arivo_Fast_Fund_Addendum.pdf',
    },
    {
        title: `Funding Checklist`,
        description: `Arivo's Funding Checklist of documents and information needed to fund your loans.`,
        link: '/documents/Arivo_Funding_Checklist.pdf',
    },
    {
        title: `Seller’s Assignment`,
        description: `Form for dealers to assign their interest in a Retail Installment Contract to Arivo.`,
        link: `/documents/Arivo_Seller_Assignment.pdf`,
    },
]
</script>
