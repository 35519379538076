<template>
    <div class="flex flex-col w-full min-h-[106px] rounded-sm">
        <div class="px-6 py-4 border-b border-black text-22px leading-7 flex justify-between">
            <div class="flex items-center gap-2">
                <v-icon testing_id="warn-icon" size="24" icon="$alertOutline" color="error" />
                <h1>{{ message.title }}</h1>
            </div>
            <v-btn
                v-if="message.dismissable"
                data-testid="close-icon"
                icon="$windowClose"
                density="compact"
                color="gray-700"
                @click.prevent="$emit('closeInfoBox')" />
        </div>
        <div class="flex flex-col h-full justify-between p-6 text-sm tracking-[.25px] grow">
            <p>{{ message.text }}</p>
            <p class="text-xs text-left font-medium whitespace-pre-wrap mt-7">For assistance with Pinpoint or to consult with our experts about enrolling in our program,
                <a class="underline font-semibold" href="https://arivo.com" target="_blank">click here</a> or call
                {{ number[10] }}
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useApplicationStore } from '@/stores/application'
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue'
import number from '@/constants/phoneNumbers'

defineComponent({ name: 'Info' })
// TODO: Get the title and message from the backend?
type Message = {
    dismissable: boolean
    title: string
    text: string
}

interface MsgNames {
    [key: string]: Message
}

// define props
const props = defineProps({
    index: { type: String, required: true },
})

// initializations
const applicationStore = useApplicationStore()
const messages: MsgNames = {
    declined: {
        dismissable: false,
        title: 'The Account you have attempted to enter is Declined',
        text: 'PinPoint cannot be used for deals that have been declined. If the account you attempted to enter is seemingly declined by mistake, you can reach out to your Arivo Team at the phone number in the top right corner.  You can also navigate to your home Dashboard, Approvals, Funding or Booked deals from this page, in the top header.',
    },
    undecided: {
        dismissable: false,
        title: 'The Account you have attempted to enter is Undecided',
        text: 'This Application has not yet had a decision made. PinPoint is unable to get any information for this deal yet.',
    },
    other_status: {
        dismissable: false,
        title: `This Application is in ${applicationStore.account?.status.status ?? 'Unknown'} Status with Arivo.`,
        text: 'Please contact your Dealer Services team if you have any further questions regarding this application.',
    },
    expired: {
        dismissable: false,
        title: 'Application Expired',
        text: 'The Application you are attempting to enter is Expired',
    },
    error: {
        dismissable: false,
        title: 'An error occurred.',
        text: 'Please retry the link provided for your Application.  If the error persists, please contact your Arivo rep for help.  Thank you.',
    },
    notAuthorized: {
        dismissable: false,
        title: 'Unable to access Pinpoint',
        text: 'In order to to use PinPoint please enter through a link provided in your DMS',
    },
    deactivated: {
        dismissable: false,
        title: 'Deactivated pinpoint account',
        text: 'Your Pinpoint account is showing as deactivated. Arivo will not be able to fund any deals until your account is reactivated. You won’t be able to access Pinpoint or submit loan applications with Arivo until your account is re-activated. Please contact your assigned Arivo Dealer Services representative at (801)993-0400 to resolve this issue.',
    },
    vehicleNotFound: {
        dismissable: false,
        title: 'Vehicle Not Found',
        text: 'The vehicle you are looking for is not in the inventory. Please check the VIN and try again.',
    },
    invalidVehicle: {
        dismissable: false,
        title: 'Invalid Vehicle Mileage',
        text: 'The mileage provided is incorrect for current year vehicles. Contact a Dealer Service Representative for support',
    },
    maintenance: {
        dismissable: false,
        title: 'Maintenance',
        text: 'PinPoint is currently undergoing maintenance. Please try again later.',
    },
}
const message: ComputedRef<Message> = computed(() => messages[props.index] || messages.error)
</script>
